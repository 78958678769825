enum Filter {
  All = 'ALL',
  Created = 'CREATED',
  Answered = 'ANSWERED',
  NotAnswered = 'NOT_ANSWERED',
  Followed = 'FOLLOWED',
  Top = 'TOP',
  Terminated = 'TERMINATED',
}

export default Filter
